import _ from "lodash";

/**
 * 関数形式の日付フォーム (Y-M-D)
 * @param date
 * @return {string}
 */
export const formatDate = (date) => {
  if (date) {
    const arrayDate = date ? date.split('-') : [];
    return `${arrayDate[0] || 0}年${arrayDate[1] || 0}月${arrayDate[2] || 0}日`;
  }
};

/**
 * 機能フォーマット電話番号
 * @param phoneNumber
 * @returns {string}
 */
export const formatPhoneNumber = (phoneNumber) => {
  const cleaned = String(phoneNumber).replace(/\D/g, '');
  const match = cleaned.match(/^(\d{3})(\d{4})(\d+)$/);
  if (match) {
    return `${match[1]}-${match[2]}-${match[3]}`;
  }
  return '';
};

/**
 * 機能フォーマット電話番号
 * @param zipCode
 * @returns {string}
 */
export const formatZipCode = (zipCode = '') => {
  if (zipCode) {
    zipCode = zipCode.slice(0, 3) + '-' + zipCode.slice(3);
    return `〒${zipCode}`;
  }
  return '';
};

/**
 * コード変換あ
 * @param enums
 * @param value
 * @param defaultValue
 * @returns {*|string}
 */
export const convertEnum = (enums, value, defaultValue = '') => {
  const enumObj = _.find(enums, ['value', value]);
  if (!enumObj || enumObj.value === 'e') {
    return defaultValue;
  }
  return enumObj.text || enumObj.label;
};

/**
 * Convert enum to value
 * @param enums
 * @param value
 * @param defaultValue
 * @param typeInput
 * @returns {*|string}
 */
export const convertEnumTextToValue = (enums, value, defaultValue = null, typeInput = 'text') => {
  if (!value) return null;
  value = !isNaN(value) ? Number(value) : value;
  const enumObj = _.find(enums, item => {
    if (typeInput === 'text' && _.isNil(item[typeInput])) typeInput = 'label';
    const enumValue = !isNaN(item[typeInput]) ? Number(item[typeInput]) : item[typeInput];
    return enumValue === value;
  });
  return enumObj?.value || defaultValue;
};

/**
 * m2を坪に変換する
 * @param squareMeters
 * @returns {*}
 */
export const convertSquareMetersToTsubo = (squareMeters) => {
  return roundToTwoDecimalDigits(squareMeters / 3.306);
};

/**
 * 小数点以下第2位を四捨五入する関数
 * @param decimalNumber
 * @returns {number}
 */
export const roundToTwoDecimalDigits = (decimalNumber) => {
  return Math.round((decimalNumber + Number.EPSILON) * 100) / 100;
};

/**
 * オブジェクトのプロパティを除外する関数
 * @param obj
 * @param keys
 * @returns {{}}
 * @private
 */
export const excludePropertiesObj = (obj, keys) => {
  const target = {};
  for (const i in obj) {
    if (keys.indexOf(i) >= 0) continue;
    if (!Object.prototype.hasOwnProperty.call(obj, i)) continue;
    target[i] = obj[i];
  }
  return target;
};

/**
 * Remove insignificant trailing zeros
 * @param number
 * @returns {string}
 */
export const removeZeros = (number) => {
  return parseFloat(number).toString();
};

/**
 * convert 平米 -> 坪
 * @param val
 * @returns {string}
 */
export const calcAreaM2T = (val) => {
  return Math.floor(val * 0.3025 * 100) / 100 + '坪';
};

/**
 * アクション実行日時（年）
 * @param year
 * @returns {*}
 */
export const getYear = (year) => {
  return $nuxt.$moment(year).format('YYYY')
};

/**
 * アクション実行日時（日付）
 * @param day
 * @returns {*}
 */
export const getDate = (day) => {
  return $nuxt.$moment(day).format('MM-DD')
};

/**
 * アクション実行日時（時間）
 * @param time
 * @returns {*}
 */
export const getTime = (time) => {
  return $nuxt.$moment(time).format('HH:mm')
};

/**
 * format link in text
 * @param inputText
 * @param isCreateTag
 * @returns {*}
 */
export const linkify = (inputText, isCreateTag = true) => {
  //URLs starting with http://, https://, or ftp://
  const replacePattern1 = /(\b(https?|ftp):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/gim;
  let replacedText
  if (isCreateTag) {
    replacedText = inputText.replace(replacePattern1, '<a href="$1" target="_blank">$1</a>');
  } else {
    replacedText = inputText.replace(replacePattern1, '$1');
  }

  //URLs starting with www. (without // before it, or it'd re-link the ones done above)
  const replacePattern2 = /(^|[^\/])(www\.[\S]+(\b|$))/gim;
  if (isCreateTag) {
    replacedText = replacedText.replace(replacePattern2, '$1<a href="http://$2" target="_blank">$2</a>');
  } else {
    replacedText = inputText.replace(replacePattern2, 'http://$2');
  }

  if (!isCreateTag) {
    if (!/^(?:f|ht)tps?\:\/\//.test(replacedText)) {
      replacedText = "http://" + replacedText;
    }
  }
  //Change email addresses to mailto:: links
  const replacePattern3 = /(\w.+@[a-zA-Z_]+?\.[a-zA-Z]{2,6})/gim;
  replacedText = replacedText.replace(replacePattern3, '<a href="mailto:$1">$1</a>');

  return replacedText
};

export const addBlockQuote = (string) => {
  const regRexHtmlTag = new RegExp(`(\<\/?(p|h1|ul|ol)\>)`, 'g');
  let quoteStr = string.replaceAll('</p><p>', "\n");//break line
  quoteStr = quoteStr.replaceAll(regRexHtmlTag, '');//remove html tag
  return `<blockquote>${quoteStr}</blockquote>\n`;
};

/**
 * Get file size with unit
 * @param fileSize (Byte)
 * @returns {string}
 */
export const getFileSizeWithUnit = (fileSize) => {
  const options = {
    notation: "compact",
    style: 'unit',
    unit: 'byte',
    unitDisplay: "narrow"
  }
  return new Intl.NumberFormat('en', options).format(fileSize);
}

/**
 * @param histories
 * @param object
 * @param labels
 * @param constants
 * @param skipKey
 * @param relations
 * @returns {string}
 */
export const getHistoryText = (histories, object, labels, constants, skipKey = [], relations = []) => {
  let content = '';
  if (!histories || !constants) return '';
  if (!_.isArray(histories)) return '';
  try {
    const length = histories.length;
    histories.map((data, index) => {
      const contents = data.content.split("\n");
      let newObject = null;
      if (!_.get(contents, 1) || _.get(contents, 1) === '') return '';
      const oldObject = JSON.parse(_.get(contents, 1));
      if (index < length - 1) {
        const newContents = histories[index + 1].content.split("\n");
        newObject = JSON.parse(newContents[1]);
      } else if (index === length - 1) {
        newObject = object;
      }
      const historyContent = getContentFromObject(oldObject, newObject, object, labels, constants, skipKey, relations);
      if (historyContent !== '' && historyContent !== '\n') {
        content += `${contents[0]}\n`;
        content += historyContent;
      }
    });
    return content;
  } catch (e) {
    console.log(e)
    return '';
  }
}

/**
 * @param oldObject
 * @param newObject
 * @param object
 * @param labels
 * @param constants
 * @param skipKey
 * @param relations
 * @returns {string}
 */
export const getContentFromObject = (oldObject, newObject, object, labels, constants, skipKey = [], relations = []) => {
  let content = '';
  for (let key in object) {
    if (skipKey.includes(key)) continue;
    if (!_.get(labels, key, false)) {
      continue;
    }
    if ((_.isArray(_.get(newObject, key)) && !_.get(oldObject, key) && !_.isEmpty(_.get(newObject, key))) || (_.isArray(_.get(oldObject, key)) && !_.get(newObject, key) && !_.isEmpty(_.get(oldObject, key)))) {
      content += `${getTitleFromConst(key, labels)}\n`;
    }
    if (_.isArray(_.get(newObject, key)) && _.isArray(_.get(oldObject, key))) {
      if (oldObject[key]?.length === 0 && oldObject[key]?.length === newObject[key].length) continue;
      let arrayChanges = 0;
      for (let item of oldObject[key]) {
        const find = newObject[key].find(data => {
          if (Object.keys(relations).includes(key)) {
            for (let relation of relations[key]) {
              if (_.isArray(relations[relation])) {
                for (let arrayKey in _.get(data, relation, [])) {
                  for (let relationKey of relations[relation]) {
                    const rKey = `${relation}.${arrayKey}.${relationKey}`;
                    if (_.get(data, rKey) !== _.get(item, rKey)) return false;
                  }
                }
                continue;
              }
              if (data[relation] !== item[relation]) return false;
            }
            return true;
          }
          return data[Object.keys(object[key])[0]] === item[Object.keys(object[key])[0]];
        })
        if (!find) {
          arrayChanges++;
          break;
        }
      }
      for (let item of newObject[key]) {
        const find = oldObject[key].find(data => {
          if (Object.keys(relations).includes(key)) {
            for (let relation of relations[key]) {
              if (_.isArray(relations[relation])) {
                for (let arrayKey in _.get(data, relation, [])) {
                  for (let relationKey of relations[relation]) {
                    const rKey = `${relation}.${arrayKey}.${relationKey}`;
                    if (_.get(data, rKey) !== _.get(item, rKey)) return false;
                  }
                }
                continue;
              }
              if (data[relation] !== item[relation]) return false;
            }
            return true;
          }
        })
        if (!find) {
          arrayChanges++;
          break;
        }
      }
      if (arrayChanges > 0) {
        content += `${getTitleFromConst(key, labels)}\n`;
      }
      continue;
    }
    if (_.isObject(oldObject[key]) || _.isObject(newObject[key])) {
      let arrayChanges = getContentFromObject(_.get(oldObject, key, {}), _.get(newObject, key, {}), _.get(object, key, {}), labels, constants, skipKey, relations);
      if (arrayChanges && arrayChanges !== '\n' && arrayChanges !== '') {
        content += `${getTitleFromConst(key, constants.historyLabels)}\n${arrayChanges}`;
      }
      continue;
    }
    if (!isNaN(oldObject[key]) && !isNaN(newObject[key]) && parseFloat(newObject[key]) === parseFloat(oldObject[key])) continue;
    if (!isNaN(oldObject[key]) && !isNaN(newObject[key]) && (oldObject[key] !== null || newObject[key] !== null)) {
      if (!_.get(labels, key + '.enum', false)
        && !_.get(labels, key + '.relation', false)
        && !_.get(labels, key + '.data', false)) {
        content += `${getTitleFromConst(key, labels)}: ${Math.round(oldObject[key])}->${Math.round(newObject[key])}\n`;
        continue;
      }
      content += `${getTitleFromConst(key, labels)}: ${getValueFromConst(oldObject, key, oldObject[key], labels, constants)}->${getValueFromConst(newObject, key, newObject[key], labels, constants)}\n`;
      continue;
    }
    if (oldObject[key] !== newObject[key]
      && !_.isObject(newObject[key]) && !_.isObject(oldObject[key])
      && getValueFromConst(oldObject, key, oldObject[key], labels, constants) !== getValueFromConst(newObject, key, newObject[key], labels, constants)
    ) {
      content += `${getTitleFromConst(key, labels)}: ${getValueFromConst(oldObject, key, oldObject[key], labels, constants)}->${getValueFromConst(newObject, key, newObject[key], labels, constants)}\n`;
    }
  }
  return content.replace('\n\n', '\n') + '\n';
}

/**
 * @param key
 * @param constants
 * @returns {*}
 */
const getTitleFromConst = (key, constants = {}) => {
  return _.get(constants, key + '.title', key);
}

/**
 * @param object
 * @param key
 * @param value
 * @param labels
 * @param constants
 * @returns {*}
 */
const getValueFromConst = (object, key, value, labels, constants = {}) => {
  const valueData = _.isEmpty(value) || value === 'null' ? "未設定" : value;
  if (!key) return valueData;
  if (!_.get(labels, key)) return valueData;
  const label = labels[key];
  if (!_.isNil(label.enum)) {
    const data = _.get(constants, label.enum.path, []);
    return _.get(data.find(i => i.value === value), 'text', valueData);
  }

  if (!_.isNil(label.relation)) {
    return _.get(object, label.relation.name + '.' + label.relation.column, valueData)
  }

  if (!_.isNil(label.data)) {
    const data = _.get(constants, label.data.type, []);
    return _.get(data.find(i => i.key === value), 'value', valueData);
  }
  return valueData;
}
